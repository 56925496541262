@font-face {
    font-family: "din-next-lt";
    src: url("../font/din-next-lt/DINNEXTLTW23-BOLD.TTF");
    font-weight: bolder;
    font-display: swap;
}

@font-face {
    font-family: "din-next-lt";
    src: url("../font/din-next-lt/DINNEXTLTW23-LIGHT.TTF");
    font-weight: lighter;
    font-display: swap;
}

@font-face {
    font-family: "din-next-lt";
    src: url("../font/din-next-lt/DINNEXTLTW23-REGULAR.TTF");
    font-weight: 500;
    font-display: swap;
}