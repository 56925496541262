@import "swiper/swiper-bundle";

:root {
  --primary: #ec2424;
  --secondry: #cb1a1a;
  --text-color: #484848;
}

body {
  font-family: "din-next-lt";
  font-weight: bolder !important;
  background-color: #ffffff;
  color: var(--text-color);
  font-display: swap;
}

// TODO: Move the following lines to the library
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
}
.decorated > span:before,
.decorated > span:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: #b1b1b1 1px solid;
  width: 100vw;
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}

.narrow-number {
  letter-spacing: -5px;
}

.text-larger {
  font-size: 4.4em;
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  .text-larger {
    font-size: 3em;
  }  
}

.text-larger-1 {
  font-size: 7em;
}

a {
  text-decoration: none;
}

.bg-gray {
  background-color: #ebeff0;
}

.btn-alofoq {
  background-color: var(--primary);
  color: white;
}

.btn-alofoq:hover {
  background-color: var(--secondry);
  color: white;
}

.bg-alofoq {
  background-color: var(--primary);
}

.text-alofoq {
  color: var(--primary) !important;
}

.text-success-light {
  color: #54a755 !important;
}

.alofoq-pagination, .ngx-pagination {
  margin-bottom: 0 !important;
  padding: 0 !important;
  .page-link, li {
    color: #676161;
    border: 0 !important;
    border-radius: 50%;
    margin: 0px;
    padding: 0.1rem 0.5rem;
    font-size: smaller;
    opacity: 0.7;
    border-radius: 25px !important;
    &.active, &.current {
      color: black;
      background-color: #f7f7f7;
    }
    &:hover, a:hover {
      opacity: 1 !important;
      &:not(.active) {
        background-color: transparent !important;
      }
    }
  }
}

.bg-success-light {
  background-color: #54a755 !important;
}

.border-success-light {
  border-color: #54a755 !important;
}

.btn-success-light {
  background-color: #54a755 !important;
  border-color: #54a755 !important;
}

.hero-centered-slider .owl-item img {
  border-radius: 15px;
  box-shadow: 0px 0px 5px #28272730;
}

.font-size-small {
  font-size: small;
}

.input-group-rounded {
  input {
    border-radius: 0 0.50rem 0.50rem 0;
  }
  .input-group-append button {
    font-weight: 500;
    border-radius: 0.50rem 0 0 0.50rem;
  }
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: #fffcfc !important;
  background: #fffcfc !important;
}

.gradient-balance {
  background: rgb(41, 106, 112);
  background: -moz-linear-gradient(
    45deg,
    rgba(41, 106, 112, 1) 0%,
    rgba(77, 167, 77, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(41, 106, 112, 1) 0%,
    rgba(77, 167, 77, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(41, 106, 112, 1) 0%,
    rgba(77, 167, 77, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#296a70",endColorstr="#4da74d",GradientType=1);
}

.gradient-voucher {
  background: rgb(234, 35, 30);
  background: -moz-linear-gradient(
    180deg,
    rgba(234, 35, 30, 1) 0%,
    rgba(183, 16, 96, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(234, 35, 30, 1) 0%,
    rgba(183, 16, 96, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(234, 35, 30, 1) 0%,
    rgba(183, 16, 96, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ea231e",endColorstr="#b71060",GradientType=1);
}

.glassmorphism-card {
  background: #ffffffe8;
}

.form-switch .form-check-input {
  background-position: left center !important;
  &:checked {
    background-position: right center !important;
  } 
}

.clickable-card {
  position: relative;
  top: 0;
  margin-top: 0;
  margin-bottom: 1px;

  box-shadow: 0 10px 0 -1px rgb(0 0 0 / 5%);
  &:hover {
    box-shadow: none;
    top: 5px;
    margin-bottom: 0;
    box-shadow: 0 5px 0 -1px rgb(0 0 0 / 20%);
  }
}

.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  width: 100%;
}

.owl-theme .owl-dots .owl-dot span {
  width: 6px !important;
  height: 6px !important;
}

.ngx-charts-outer {
  width: 100% !important;
}

.horizontal-legend.legend-labels {
  display: flex;
  justify-content: center;
  background-color: white !important;
  margin-top: 10px;
}

.chart-legend .legend-label-color {
  margin-right: 0px;
  margin-left: 5px !important;
}

.ngx-charts {
  float: unset !important;
  z-index: 1;
  position: relative;
}

.outline-none:focus {
  outline: none !important;
  box-shadow: none;
}

// NGX-TOASTER
.toast-top-center {
  top: 25px;
  font-weight: normal !important;
  .ngx-toastr {
    border-radius: 5px;
  }
  .toast-title {
    font-size: 0.8em !important;
  }
  .toast-message {
    font-size: 0.9em !important;
  }
}

// NGX-SKELETON-LOADER
.skeleton-small {
  .loader {
      height: 15px !important;
      margin-bottom: 0 !important;
  }
}

// NGX PAGINATION
.ngx-pagination {
  .current {
    background: #f9f9f9 !important;
  }
}


// Large Screens
@media (min-width: 1025px) {
  .voucher-card {
      min-height: 150px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .voucher-card {
    min-height: 125px !important;
}
}

// Custom Order

$sizes: 
    "" 1px,
    "sm-" 576px,
    "md-" 768px,
    "lg-" 992px,
    "xl-" 1200px,
    "xxl-" 1400px;

@each $screen, $minWidth in $sizes {
  @media (min-width: $minWidth) {
    @for $i from 1 through 20 {
      .custom-order-#{$screen}#{$i} {
        order: $i!important;
      }
    }
  }
}
